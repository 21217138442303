<template>
    <div class="container">
        <MobileHeaderBack ></MobileHeaderBack>
        <div v-if="isMobile"><img class="w-100" src="../../../static/images/signup_bg_webapp_1.png"></div>
        <div class="signup-wrapper">
            <SignupThemeInfo v-if="!isPopUp"></SignupThemeInfo>
            <div  class="signup-form__wrapper">
                <div class="w-100">
                    <template v-if="step === 1">
                        <h1 v-if="isMobile" class="signup-form__title">{{$t('WELCOME')}}</h1>
                        <h2 v-else class="signup-form__title">{{$t('SIGNUP')}}</h2>
                    
                        <!-- phone number -->
                        <div class="form-group">
                            <label class="form-label">{{$t('PHONE_NUMBER')}}</label>
                            <div class="display-flex relative">
                                <div class="display-flex-center signup-form__phone-prefix"  @click="handleDropdown($event)">
                                    <span class="mr-1rem">+{{ selectedCountry.callingCode }}</span>
                                    <img src="../../../static/images/icons/icon_arrow_down_white.png">
                                </div>
                                <Dropdown ref="dropdownRef" :data="countryList" @dropDownChange="dropDownChange($event)"></Dropdown>
                                <input class="form-control"  pattern="[0-9]*" type="text" v-model="phoneNumber.inputValue" v-on:keyup="inputPhoneNumberEvent"  v-on:keyup.enter="handleSignUpBtn()" :placeholder="$t('PHONE_NUMBER')">
                            </div>
                            <div class="form-tips">
                                <div v-if="phoneNumber.isError" class="error">{{$t('PHONE_NUMBER_ERROR_MSG')}}</div>
                                <div v-else>(+{{selectedCountry.callingCode}})
                                    <span v-if="selectedCountry.callingCode === 66">098765432</span>
                                    <span v-else-if="is8PhoneNo">12345678</span>
                                    <span v-else-if="is10PhoneNo">1234567890</span>
                                    <span v-else-if="is11PhoneNo">12345678901</span>
                                    <span v-else>123456789</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-signup__btn-wrapper">
                            <div class="mb-1rem">
                                <button class="form-control signup-form__disable" :class="{'signup-form__enable': isSignUpBtnEnable }" @click="handleSignUpBtn()">{{$t('SIGNUP')}}</button>
                            </div>
                            <div class="display-flex-center mb-1rem">
                                <span class="signup-form__seperator-line"></span>
                                <span class="signup-form__seperator-or">{{$t('OR')}}</span>
                                <span class="signup-form__seperator-line"></span>
                            </div>
                            <div class="mb-1rem">
                                <div v-if="isPopUp" @click="handlePopUpSignUp('signIn')"><button class="form-control btn-outline pointer" :class="{'signup-form__enable': isSignInBtnEnable }" >{{$t('LOGIN')}}</button></div>
                                <router-link v-else :to="{ name: 'signin' , query: { callBackUrl: callBackUrl }}"><button class="form-control btn-outline pointer" :class="{'signup-form__enable': isSignUpBtnEnable }">{{$t('LOGIN')}}</button></router-link>
                            </div>
                        </div>
                    </template>
                    <!-- otp -->
                    <template v-if="step === 2">
                        <div class="form-group">
                            <label class="form-label">{{$t('OTP')}}</label>
                            <div class="display-flex">
                                <!-- :disabled="otp.disableOtpInput" -->
                                <input class="form-control mr-1rem flex-3" type="text" v-model="otp.inputValue" v-on:keyup="inputOTPEvent"  v-on:keyup.enter="postSignupData()"  :placeholder="$t('ENTER_OTP')">
                                <div class="">
                                    <button v-if="otp.isShowSendOTP" class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableSendOtpBtn }" @click="handleGetSignupCode('send')">{{$t('SEND_OTP')}}</button>
                                    <button v-else class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableResendOtpBtn }" @click="handleGetSignupCode('resend')">{{$t('RESEND')}}<span v-if="!otp.isEnableResendOtpBtn"> ({{otp.currentResetTime}}s)</span></button>
                                </div>
                            </div>
                            <div class="form-tips">
                                <div v-if="!otp.isShowSendOTP">{{ $t('OTP_TIP') }}</div>
                                <!-- <div v-if="otp.isError" class="error">{{$t('OTP_ERROR_MSG')}}</div> -->
                            </div>
                            <div class="signup-form__recaptcha">
                                <div v-if="isVietnamSelected" id="recaptcha-container" ></div>
                                <!-- <VueHcaptcha
                                    v-if="isChinaSelected"
                                    :sitekey="hCaptcha.siteKey"
                                    @verify="HCaptchaVerified"
                                    @expired="HCaptchaReset"
                                    @challenge-expired="HCaptchaReset"
                                    language="zh-CN"
                                /> -->
                                <!-- <vue-recaptcha v-else class="recaptcha-bg-transparent"  ref="invisibleRecaptcha" data-expired-callback="recaptchaExpired"   @expired="onExpiredRecaptcha" @verify="onVerifyRecaptcha" :sitekey="recaptcha.siteKey"></vue-recaptcha> -->
                            </div>
                            <div class="signup-form__confirm-wrapper">
                                <button class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableConfirmBtn }" @click="handleConfirmBtn">{{ $t('CONFIRM') }}</button>
                            </div>
                        </div>
                    </template>
                    <!-- referral -->
                    <template v-if="step === 3">
                         
                         <h2 class="signup-form__title">{{$t('ENTER_REFERRAL_CODE')}}</h2>               
                        <!-- referral code -->
                        <div class="form-group">
                            <label class="form-label">{{$t('REFERRAL_CODE_SIGN_IN')}}</label>
                            <div class="form-group__container">
                                <input class="form-control" v-model="referral.inputReferralCode" v-on:keyup="inputReferralCodeEvent" v-on:paste="inputReferralCodePasteEvent" :placeholder="$t('ENTER_CODE')">
                            </div>
                        </div>
                        <div class="form-signup__btn-wrapper mt-15rem">
                            <div class="mb-1rem">
                                <button class="form-control signup-form__disable" :class="{'signup-form__enable': referral.isNextBtnEnable }" @click="postSignupReferralData()">{{$t('NEXT')}}</button>
                            </div>
                            <div class="tc font-m td-underline">
                                <span class="pointer" @click="handleReferralSkip()">{{$t('SKIP')}}</span>
                            </div>
                        </div>
                    </template>
                    
                </div>
            </div>
 
           
        </div>
        <div v-if="isMobile"><img class="w-100" src="../../../static/images/signup_bg_webapp_2.png"></div>
    </div>
</template>

<script>
const SIGNUP_METHOD = 'PHONE';

import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import SignupThemeInfo from '@/components/user/SignupThemeInfo.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import Footer from '@/components/Footer.vue'
import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import signInZalo from '@/js/user/signInZalo.js'

import { getAuth, RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";

import config from '@/js/config.js'
import {mapGetters,mapMutations,mapActions} from 'vuex'


export default {
    props: {
        isPopUp : {
            type: Boolean,
            default: false
        },
        isShowWelcomeMsg: {
            type: Boolean,
            default: true
        }
    },
    components: {
        SignupThemeInfo,
        Dropdown,
        MobileHeaderBack,
        Footer,
        VueHcaptcha,
    },
    data() {
        return {
            showPassword : false,
            showConfirmPassword: false,

            countryList: config.countryList.sort((a, b) => a.name.localeCompare(b.name)),

            selectedCountry: {
                callingCode:''
            },


            isSignUpBtnEnable: false,

            phoneNumber: {
                inputValue: '',
                isError: false
            },

            password: {
                inputValue: '',
                isError: false
            },

            confirmPassword: {
                inputValue: '',
                isError: false
            },

            nickName: {
                inputValue: '',
                isError: false
            },

            otp: {
                inputValue: '',
                isError: false,
                isEnableResendOtpBtn: false,

                isShowSendOTP: true,
                isEnableSendOtpBtn: true,
                isEnableConfirmBtn: false,

                disableOtpInput: false,

                currentResetTime: null,
                resetDuration: 120   
            },
            
            step: 1,

            referral: {
                isShow: false,
                isNextBtnEnable: false,
                inputReferralCode: '',
            },
            callBackUrl: "",
            recaptcha: {
                value:'',
                siteKey: config.recaptchaSiteKey
            },
            // hCaptcha: {
            //     siteKey: config.hCaptchaSiteKey,
            //     response: null,
            // },

            isVietnamSelected: false,
            isChinaSelected: false,

            auth: null,
            appVerifier: null,

            is8PhoneNo: false,
            is10PhoneNo: false,
            is11PhoneNo: false,
        }
    },
    computed: {
        ...mapGetters([
            'currentLanguageObj',
            'currentLanguageList',
            'isMobile',
            "userCountryCode",
        ]),
    },
    beforeUnmount() {
        clearInterval(this.otp.interval);
    },
    mounted() {
         this.init();
         this.getCurrentLocationData();

    },
    methods: {
        ...mapMutations([
            "SET_USER_COUNTRY_CODE",
        ]),
         ...mapActions([
            "getWSUP",
            "setUserData",
            "getCurrentLocation",
            "getCountryCodeByIp",
            "postSignup",
            "postUpdateReferralCode",
            "getSignUpMethod",
            "popUpSignInModuleEvent",
            "getContentList",
            "navigationMenuEvent",
            "toastrChangeEvent",
            "currentLocaleChangeEvent",
            "postFirebaseSignup",
            "currentCountryChangeEvent",
        ]),
        init() {
            this.syncCallbackUrl();
        },
        HCaptchaVerified(tokenStr, ekey) {
            this.hCaptcha.response = tokenStr;
            this.submitRecaptchaToBackend("send");
        },
        HCaptchaReset() {
            this.hCaptcha.response = null;
            // this.otp.isEnableSendOtpBtn = false;
            // this.otp.disableOtpInput = true;
        },
        syncCallbackUrl(){
            let routeCallBackUrl = this.$route.query.callBackUrl;
            this.callBackUrl = routeCallBackUrl;  
        },

        async inputPhoneNumberEvent() {
            this.phoneNumber.inputValue = this.$tools.onlyNumber(this.phoneNumber.inputValue);
            let minLength = 6;
            let maxLength = [55, 86].includes(this.selectedCountry.callingCode) ? 11 : 10;

            if (this.phoneNumber.inputValue.length > maxLength) {
                this.phoneNumber.inputValue = this.phoneNumber.inputValue.slice(0, maxLength)
            }

            if (this.phoneNumber.inputValue.length >= minLength && this.phoneNumber.inputValue.length <= maxLength ) {
                this.phoneNumber.isError = false;
                this.isSignUpBtnEnable = true;

            } else {
                this.phoneNumber.isError = true;
                this.isSignUpBtnEnable = false;
            }      
        },
     
        inputOTPEvent() {
          this.otp.isEnableConfirmBtn = true;
        },

        verifyAllInput() {
            if (this.phoneNumber.inputValue.length > 0) {
                if (this.phoneNumber.isError) {
                    this.isSignUpBtnEnable = false;
                } else {
                    this.isSignUpBtnEnable = true;
                }
            } else {
                this.isSignUpBtnEnable = false;
            }            
        },

        handleDropdown(e){
             this.$refs.dropdownRef.handleOpen(e);              
        },
        dropDownChange(e){
            this.selectedCountry = e;
            this.checkPhoneNo(this.selectedCountry.callingCode);
        },
        checkPhoneNo(callingCode) {
            this.is8PhoneNo = [81].includes(callingCode) ? true : false;
            this.is10PhoneNo = [55, 63].includes(callingCode) ? true : false;
            this.is11PhoneNo = [86].includes(callingCode) ? true : false;
        },
        async handleGoogleRecaptcha() {
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('submit')
            debugger
        },
        async getCurrentLocationData() {
            let matchSelectedCountry = false;
            // let timeZone = this.$tools.getCurrentTimeZone();
            let countryCode;
            if (this.userCountryCode === null) {
                const result = await this.getCountryCodeByIp();
                if (result.message === "Success") {
                    countryCode = result.result.countryCode;
                    this.currentCountryChangeEvent(countryCode);
                }
            } else {
                countryCode = this.userCountryCode;
            }

            // detect current location and selected current location country calling code
            this.countryList.forEach(x=> {
                // if (x.timeZone === timeZone) {
                if (x.countryCode === countryCode) {
                    this.$refs.dropdownRef.handleDropdownSelect(x);
                        matchSelectedCountry = true;
                    return false;
                }
            })

            //otherwise default to cambodia (AS-1432 - as first country) calling code
            if (!matchSelectedCountry) {
                this.$refs.dropdownRef.handleDropdownSelect(this.countryList[0]);
            }
        },

        handlePopUpSignUp(type) { 
            this.popUpSignInModuleEvent(type)   
            //this.$emit('switchPageEvent', type);
        },

        onVerifyRecaptcha(response) {
            this.recaptcha.value = response;
            this.otp.isEnableSendOtpBtn = true;
            this.otp.isEnableResendOtpBtn = true;
        },

        onExpiredRecaptcha(response) {
            this.recaptcha.value = "";

            // this.isSignUpBtnEnable = false;
            // this.otp.isEnableSendOtpBtn = false;
            // this.otp.isShowSendOTP = true;

            this.inputPhoneNumberEvent();
        },

        async handleGetSignupCode(type) {   
            if (this.isVietnamSelected) {
                let phoneNumber = "+" + this.selectedCountry.callingCode + this.phoneNumberVnValidation(this.phoneNumber.inputValue);
           
                signInWithPhoneNumber(this.auth, phoneNumber, this.appVerifier)
                    .then((confirmationResult) => {
                        window.confirmationResult = confirmationResult;

                        if (type === 'send') {
                            this.otp.isShowSendOTP = false;
                        } 

                        this.otpResendCountdown();
                    }).catch((error) => {
                        let params = {
                            isOpen:true,
                            msg:  "FIREBASE_ERROR",
                            type: 'info',   
                        }
                        
                        this.toastrChangeEvent(params)
                    }); 
            } else {
                let token;
                if (!this.isChinaSelected) {
                    await this.$recaptchaLoaded()

                    token = await this.$recaptcha('homepage');
                    this.recaptcha.value = token;
                }

                this.submitRecaptchaToBackend(type);
            }          
        },
        async submitRecaptchaToBackend(type) {
            let params = {
                method: SIGNUP_METHOD, //now we only have phone signup. method, i.e.: PHONE, EMAIL
                username: this.selectedCountry.callingCode + this.phoneNumber.inputValue,
            }

            if (!this.isChinaSelected) {
                params["g-recaptcha-response"] = this.recaptcha.value;
                params["captchaMethod"] = 2;
            }
            
            // if (this.isChinaSelected) {
            //     params["h-captcha-response"] = this.hCaptcha.response;
            //     params["captchaMethod"] = 3;
            // }
            const result = await this.getWSUP(params);

            if (result.result.businessCode === 0) {
                this.otpResendCountdown();
                if (type === 'send') {
                    this.otp.isShowSendOTP = false;
                } 
                // if (this.isChinaSelected) {
                //     this.otp.isEnableSendOtpBtn = true;
                //     // this.otp.disableOtpInput = false;
                // }
            } else {
                let params = {
                    isOpen:true,
                    msg:result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params);
            }
        },
        otpResendCountdown() {
            this.otp.currentResetTime = this.otp.resetDuration;
            this.otp.isEnableResendOtpBtn = false;
        
            this.otp.interval = setInterval(() => {
                if (this.otp.currentResetTime > 0) {// only positive number 
                    this.otp.currentResetTime--;
                }
                
                //enable button when reset is 0
                if (this.otp.currentResetTime === 0) {

                    this.otp.isEnableResendOtpBtn = true;
                    this.recaptcha.value = "";
                    
                    clearInterval(this.otp.interval);
                }
            }, 1000);  
        },

       async handleSignUpBtn() {
            let param = {
                username: this.selectedCountry.callingCode + this.phoneNumber.inputValue,
            }

            const result = await this.getSignUpMethod(param);
            if (result.result.businessCode === 0) {
                if (this.isSignUpBtnEnable) {
                    this.step = 2;
        
                    if (this.selectedCountry.id === 'Vietnam') {
                        this.isVietnamSelected = true;
                        this.$nextTick(() => {
                            this.otp.isEnableSendOtpBtn = true; 
                            this.initFirebase();
                        })                
                    } else if (this.selectedCountry.id === 'China') {
                        this.isChinaSelected = true;
                        // this.$nextTick(() => {
                        //     this.otp.isEnableSendOtpBtn = false;
                        //     this.otp.disableOtpInput = true;
                        // })
                    } else {
                        this.isVietnamSelected = false;
                        this.isChinaSelected = false;
                    }  
                }
            } else {
                let params={
                    isOpen:true,
                    msg:result.result.message,  
                    type:'info'                   
                }
                
                this.toastrChangeEvent(params)
            }
    
        },

        initFirebase(){
            var firebaseConfig = {
                apiKey: config.RECAPTCHA_API_KEY,
                authDomain: config.RECAPTCHA_AUTH_DOMAIN,
                projectId: config.RECAPTCHA_PROJECT_ID,
                storageBucket: config.RECAPTCHA_STORAGE_BUCKET,
                messagingSenderId: config.RECAPTCHA_MESSAGING_SENDER_ID,
                appId: config.RECAPTCHA_APP_ID,
                databaseURL: config.FIREBASE_DATABASE_URL
            };
        
            getApps().length === 0 ? initializeApp(firebaseConfig) : getApps();

            this.auth = getAuth();
            this.auth.languageCode = 'vi';

            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    this.recaptcha.value = response;
                    this.otp.isEnableSendOtpBtn = true;                 
                },
                'expired-callback': (respone) => {
                    //  this.recaptcha.value = "";
                    //  this.verifyAllInput();
                }
            }, this.auth);

            recaptchaVerifier.render().then((widgetId) => {
                window.recaptchaWidgetId = widgetId;
            });

           this.appVerifier = window.recaptchaVerifier;
        },

        handleConfirmBtn() {
            if (this.isVietnamSelected) {
                this.postSignupDataVietnam();
            } else {
                this.postSignupData();
            }
        },

        async postSignupData() {
            if (!this.isSignUpBtnEnable) {
                return
            }
           
            let params = {
                username: this.selectedCountry.callingCode + this.phoneNumber.inputValue,
             	code: this.otp.inputValue,
                method: SIGNUP_METHOD,
                preferredLanguage: this.currentLanguageObj.locale,
                preferredCountry: this.userCountryCode,
            }
           

            const result = await this.postSignup(params);

            if (result.result.businessCode === 0) {
                 this.signupSucuess(result);
            } else {
                let params={
                    isOpen:true,
                    msg: result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params)
            } 
        },

        async postFirebaseSignupData(data) {
            let params = {
                username: this.selectedCountry.callingCode + this.phoneNumber.inputValue,
                idToken: data.user.accessToken,
                preferredLanguage: this.currentLanguageObj.locale,
                preferredCountry: this.userCountryCode,
            }

            const result = await this.postFirebaseSignup(params);
            
            if (result.result.businessCode === 0) {
                 this.signupSucuess(result);
            } else {
                let params={
                    isOpen:true,
                    msg: result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params)
            } 
        },

        async postSignupDataVietnam() {
            const code = this.otp.inputValue;
            confirmationResult.confirm(code).then((data) => {
                this.postFirebaseSignupData(data);
            }).catch((error) => {
               let params = {
                    isOpen:true,
                    msg: "INVALID_CODE",  
                    type:'info',
                }               
                this.toastrChangeEvent(params)
            });
        },

        signupSucuess(result){        
            if (result.result.businessCode === 0) {
                if (typeof this.$route.query.code !== 'undefined') {
                    this.referral.inputReferralCode = this.$route.query.code;
                } else {
                    this.referral.inputReferralCode = "";
                }
                this.inputReferralCodeEvent();
                this.step = 3;
                               
                this.setUserData(result.result.data);

                this.signUpSucuessData = result.result;  
            
            } else if (result.result.businessCode === 100025) {
                this.otp.isError = true;
            } else {
                let params={
                    isOpen:true,
                    msg:result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params)
            }      
        },

        async getContentListData() {
            let params = {
                timeType: 2,
                language: this.currentLanguageObj.locale,
                lang: this.currentLanguageObj.apiParam,
            }
         
            const result = await this.getContentList(params);

            let returnResult = result.result;

            this.navigationMenuEvent(returnResult);
        },

        
        handleReferralSkip() {
             let locale = this.signUpSucuessData.data.preferredLanguage;
             locale = this.currentLanguageList.filter(x => x.locale === locale)[0].displayLocale;

             if (this.isPopUp) {

                this.$emit('loginSucuessEvent','signUp');
                this.currentLocaleChangeEvent(locale);
             
                 if (this.isShowWelcomeMsg) {
                    let params={
                        isOpen:true,
                        msg:'WELCOME_TO_SPORTS168'
                    }
                    this.toastrChangeEvent(params)
                }
   
                  if (!this.signUpSucuessData.data.walkthroughFlag){
                        // Enable on basketball Phase 2
                        // this.$router.push({name: "home"})
                    }
                //this.$root.toastrMsg ='WELCOME_TO_SPORTS168';
                //this.$root.$refs.toastrRef.handleOpen();
             } else {
                let callBackUrl = "";
                let callBackUrlArray = [];
                let addRedirectWord = "";

                let routeCallBackUrl = this.$route.query.callBackUrl;
                if (routeCallBackUrl) {
                    // Enable on basketball Phase 2
                    // if (!this.signUpSucuessData.data.walkthroughFlag){
                    //     this.$router.push({name: "home"})
                    // } else {
                        //always use the locale that come from user info api
                        callBackUrlArray = routeCallBackUrl.split('/');
                        callBackUrlArray[1] = locale;
                        callBackUrl =  callBackUrlArray.join('/');

                        if (callBackUrl.includes('?')) {
                            addRedirectWord = "&";
                        } else {
                            addRedirectWord = "?";
                        }
                        callBackUrl = callBackUrl + `${addRedirectWord}redirectFrom=signUp`;   
                    // }
                }
               window.scrollTo(0,500);
                setTimeout(() => {  
                    
                    if (callBackUrl === "") {
                        this.$router.push(`/${locale}`); 
                    }else {
                        this.$router.push(`${callBackUrl}`); 
                    }
                 }, 300);
                      
             
             

                if (routeCallBackUrl !== '') {
                    if (callBackUrlArray[2] !== 'event') {
                            setTimeout(() => {
                            let params={
                                isOpen:true,
                                msg:'WELCOME_TO_SPORTS168',     
                            }
                            this.toastrChangeEvent(params)
                            // this.$root.toastrMsg ='WELCOME_TO_SPORTS168';
                            // this.$root.$refs.toastrRef.handleOpen(); 
                        }, 1000);
                    }                      
                }
             }
         
        },

        inputReferralCodePasteEvent() {
            //temporary solution for paste event handle on ios
            setTimeout(() => {
                this.inputReferralCodeEvent();
            }, 300);
        },

        inputReferralCodeEvent() {        
            if (this.referral.inputReferralCode !== '') {
                this.referral.isNextBtnEnable = true;
            } else {
                this.referral.isNextBtnEnable = false;
            }
        },

        async postSignupReferralData() {
            let params = {
                referralCode: this.referral.inputReferralCode
            }

            const result = await this.postUpdateReferralCode(params);

            if (result.result.businessCode === 0) {
                this.handleReferralSkip(result);
            } else {
                
                let params={
                    isOpen:true,
                    msg:result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params)
                // this.$root.toastrMsg = result.result.message;
                // this.$root.toastrType = 'info';
                // this.$root.$refs.toastrRef.handleOpen(); 
            }
        },

        phoneNumberVnValidation(data) {
            //if phonumber is vn, and phone number is start from 0, then remove 0
            if (this.selectedCountry.callingCodeNumOnly !== '6') {//beside malaysia phone number
                if (data.charAt(0) === '0') {
                    return data.substring(1)
                } else {
                    return data
                }
            } else {
                return data
            }
        },
    }
}
</script>

<style scoped>
 
</style>